import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';

import { Button } from '@mui/material';
import UserService from '../home/user-api';
import useAuth from '../../hooks/useAuth';

import { logout } from '../auth/auth-api';
import LogoutIcon from '@mui/icons-material/Logout';

const Header = () => {
    const navigate = useNavigate();


    const { auth, setAuth } = useAuth();

    const [userDetails, setUserDetails] = useState(null);



    const handleClickLogout = () => {
        logout();
        setAuth({ user: null, token: null });
        navigate('login');
    };


    useEffect(() => {
        const getUserDetails = async () => {
            try {

                const isOnline = navigator.onLine;
                if (isOnline) {
                    const {data} = await UserService.getUserData();
                    if (data && data.data) {
                        setUserDetails(data.data);
                        localStorage.setItem('userdetails', JSON.stringify(data.data));
                    }
                } else {
                    const cachedData = localStorage.getItem('userdetails');
                    if (cachedData) {
                        setUserDetails(JSON.parse(cachedData))
                    } else {
                        setUserDetails([]);
                    }
                }
            } catch (error) {
                console.log(error)
            }
        };

        getUserDetails();
    }, []);
    
    return (
        <AppBar
            color="inherit"
            sx={{
                height: 75,
                background: '#FAFAFA',
                justifyContent: 'space-between',
                borderBottom: '1px solid #CCD2D5',
            }}
        >
            <Toolbar
                sx={{
                    '@media (min-width:600px)': {
                        minHeight: '50px',

                    },
                }}
            >
                <Box
                    sx={{
                        '@media (max-width:599px)': {
                            marginLeft: '8px',
                        },
                    }}>
                    {userDetails?.verband_id === 1 &&
                        <img src="/BLV.png" className="logo-img" alt="Logo-Verband"/>
                    }
                    {userDetails?.verband_id === 2 &&
                        <img src="/vdh-logo-raute.svg" className="logo-img" alt="Logo-Verband"/>
                    }
                    {userDetails?.verband_id === 3 &&
                        <img src="/DVG.png" className="logo-img" alt="Logo-Verband"/>
                    }
                    {userDetails?.verband_id === 4 &&
                        <img src="/SV.png" className="logo-img" alt="Logo-Verband"/>
                    }
                    {userDetails?.verband_id === 5 &&
                        <img src="/swhv.png" className="logo-img" alt="Logo-Verband"/>
                    }
                    {!auth?.token && (
                        <img src="/vdh-logo-raute.svg" className="logo-img" alt="Logo-Verband"/>
                    )}

                </Box>
                {auth?.token && (
                    <Box ml="auto" my={2} mr={1} display="flex"
                        sx={{
                            '@media (max-width:599px)': {
                                marginRight: '8px',
                            },
                        }}>

                        <Box my={2}>
                            <Button
                                sx={{
                                    fontFamily: 'Open Sans', fontSize: '17px',
                                    fontWeight: 700, textTransform: 'none', background: '#5A6872',
                                    border: '0px, 0px, 1px, 0px !important ',
                                    width: '130px'
                                }}
                                variant="contained"
                                onClick={handleClickLogout}
                                endIcon={<LogoutIcon />}
                            >
                                Abmelden
                            </Button>
                        </Box>

                    </Box>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Header;
