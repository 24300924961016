import React from 'react';
import { Typography, Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import Vector from '../assets/icons/Vector.png'
import CardComponent from '../components/itemCards/ItemCard';

const AssocitaionDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const certificates = location.state.certificates;
    const membershipDetails = location.state.item;

    // const dateOptions = {
    //     year: 'numeric',
    //     month: '2-digit',
    //     day: '2-digit',
    // };

    return (
        <Box width='100%'>
            <Box mb={2}
                style={{
                    display: 'flex', alignItems: 'center', color: '#5A6872'
                }}
                onClick={() => navigate(-1)}
            >
                <Box mr={1}>
                    <img src={Vector} alt="iconLeft" />
                </Box>

                <Typography variant="backlink">
                    Zurück zur Übersicht
                </Typography>
            </Box>

            <Typography mb={2} variant="headertext">
                Mitgliedschaftsdetails
            </Typography>

            <Box mb={2} style={{
                background: '#F0F4F8'
            }}>
                <Box p={2}>
                    <Box>
                        <Typography variant="datalabel">
                            Verein
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext" sx={{ fontWeight: 700 }}>
                            {membershipDetails?.verein_name}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography variant="datalabel">
                            Adresse
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext">
                            {membershipDetails?.verein_strasse}<br/>
                            {membershipDetails?.verein_plz} {membershipDetails?.verein_ort}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography variant="datalabel">
                            E-Mail-Adresse
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext">
                            {membershipDetails?.verein_email}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography variant="datalabel">
                            Vereinsnummer
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext">
                            {membershipDetails?.vereins_nr}
                        </Typography>
                    </Box>

                    {(() => {if(membershipDetails?.funktionaer) {return (<>
                    <Box>
                        <Typography variant="datalabel">
                            Funktion im Verein
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext">
                            {membershipDetails?.funktionen}
                        </Typography>
                    </Box>
                    </>)}})()}

                    <Box>
                        <Typography variant="datalabel">
                            Status Mitgliedschaft
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext">
                            {membershipDetails?.beitrag_kuerzel} ({membershipDetails?.beitrag_desc})
                        </Typography>
                    </Box>

                    <Box>
                        <Typography variant="datalabel">
                            Mitgliedsnummer
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext">
                            {membershipDetails?.mitglied_nr}
                        </Typography>
                    </Box>

                </Box>
            </Box>

            <Box>
                <Typography variant="subtitle1" mb={1}>Leistungsurkunden ({certificates ? certificates?.length : 0})</Typography>
            </Box>

            <Box>
                {
                    certificates?.map((certificate) =>
                        <Box key={certificate.id}>
                            <Box>
                                <CardComponent item={certificate} isCertificate={true} certificates={certificates} fromAssociation={true} />
                            </Box>
                        </Box>
                    )

                }
            </Box>

        </Box>
    )
}

export default AssocitaionDetails;