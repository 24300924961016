import {createTheme} from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#5A6872',
            mainImportant: '#5A6872  !important',
            white: '#FFFFFF',
        },
    },
    typography: {
        fontFamily: 'Open Sans',
        subtitle1: {
            fontWeight: 700,
            fontSize: '1.1rem',
        },
        backlink: {
            fontWeight: 700,
            lineHeight: '25.2px',
            fontSize: '18px',
            color: '#5A6872',
        },
        cardtext: {
            fontWeight: 700,
            lineHeight: '19.6px',
            fontSize: 14,
        },
        formlabel: {
            marginLeft: 'auto',
            textAlign: 'left',
            fontWeight: 700,
            lineHeight: '19.6px',
            fontSize: '14px',
            marginBottom: '5px',
        },
        headertext: {
            fontWeight: 700,
            lineHeight: '33.6px',
            fontSize: '24px',
        },
        loginheadertext: {
            fontWeight: 700,
            lineHeight: '36.4px',
            fontSize: '26px',
        },
        datalabel: {
            fontWeight: 700,
            lineHeight: '16.8px',
            fontSize: '12px',
            color: '#5A6872',
        },
        datatext: {
            fontWeight: 400,
            lineHeight: '22.4px',
            fontSize: '16px',
        }
    },
    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    backlink: 'p',
                    cardtext: 'p',
                    formlabel: 'p',
                    headertext: 'p',
                    loginheadertext: 'p',
                    datalabel: 'p',
                    datatext: 'p',
                }
            }
        }
    }
});

export default theme;
