import React from 'react';
import { useForm } from 'react-hook-form';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { IconButton, InputAdornment } from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Form = function ({
	formData,
	initialValues = {},
	onSubmit,
	responseError,
	responseSuccess = null,
	children,
}) {
	const {
		register,
		handleSubmit,
		formState: { errors },
		// watch,
	} = useForm();

	return (
		<Box component="form" onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={2}>
				{formData.map(
					(
						{
							label,
							id,
							type,
							required,
							value,
							display = 'block',
							showPassword,
							handleClickShowPassword,
							handleMouseDownPassword,
							...rest
						},
						index,
					) => (
						<Grid
							sx={{
								display: display,
							}}
							key={id}
							xs={12}
						>
							{type === 'password' ? (
								<>
									<Typography variant="formlabel">
										{label}
									</Typography>
									<TextField
										fullWidth
										placeholder={label}
										inputProps={{ style: { height: '6px', color:'#5A6872' } }}
										type={showPassword ? 'text' : 'password'}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleClickShowPassword}
														onMouseDown={handleMouseDownPassword}
													>
														{showPassword ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											),
											style: {background:'#F0F4F8' }
										}}
										{...register(id, {
											value: initialValues[id] ?? '',
											required,
											// validate: (fieldValue) => fieldValue === watch('password') || '',
											...rest,
										})}
										error={!!errors?.[id]}
										helperText={errors[id]?.message} /></>
							) : (
								<>
									<Typography variant="formlabel">
										{label}
									</Typography>
									<TextField
										inputProps={{ style: { height: '6px', background:'#F0F4F8', color:'#5A6872' } }}
										fullWidth
										placeholder={label}
										type={type}
										{...register(id, {
											value: initialValues[id] ?? '',
											required,
											...rest,
										})}
										error={!!errors?.[id]}
										helperText={errors[id]?.message} /></>
							)}
						</Grid>
					),
				)}
			</Grid>
			{responseError && (
				<Typography mt={3} color="error">
					{responseError}
				</Typography>
			)}
			{responseSuccess && (
				<Typography mt={3} color="green">
					{responseSuccess}
				</Typography>
			)}
			<Grid
				key="children"
				xs={12}
				mt={2}
			>
				{children}
			</Grid>
		</Box>
	);
};

export default Form;