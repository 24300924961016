import React from 'react';
import { Typography, Box, Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Vector from '../assets/icons/Vector.png';
import Icon from '../assets/icons/Icon.png';
import TeamCardComponent from "../components/itemCards/teamCard";

const CertificateDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const certificateDetails = location.state.item;
    const fromAssociation = location.state.fromAssociation;

    const dateOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };

    const genderMap = ['Keine Angabe', 'Rüde', 'Hündin'];
    const sizeMap = ['-', 'S', 'M', 'I', 'L'];

    return (
        <Box width='100%'>
            <Box mb={2}
                style={{
                    display: 'flex', alignItems: 'center', color: '#5A6872'
                }}
                onClick={() => navigate(-1)}
            >
                <Box mr={1}>
                    <img src={Vector} alt="iconLeft" />
                </Box>

                <Typography variant="backlink">
                    Zurück zur {fromAssociation ? `Mitgliedschaft` : `Übersicht`}
                </Typography>
            </Box>

            <Typography mb={2} variant="headertext">
                Leistungsurkunde
            </Typography>

            <Box mb={2} style={{
                background: '#F0F4F8'
            }}>
                <Box p={2}>
                    <Box>
                        <Typography variant="datalabel">
                            Rufname
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext" sx={{ fontWeight: 700 }}>
                            {certificateDetails?.hundrufname ? certificateDetails?.hundrufname : 'Keine Angabe'}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography variant="datalabel">
                            Eigentümer Name
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext">
                            {`${certificateDetails?.vorname} ${certificateDetails?.name}`}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography variant="datalabel">
                            Zwingername
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext">
                            {certificateDetails?.hundname ? certificateDetails?.hundname : 'Keine Angabe'}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography variant="datalabel">
                            Geschlecht
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext">
                            {genderMap[certificateDetails?.hundgeschlecht]}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography variant="datalabel">
                            Chipnummer
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext">
                            {certificateDetails?.chipnr}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography variant="datalabel">
                            Wurftag
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext">
                            {certificateDetails?.hundwurftag ? new Date(certificateDetails?.hundwurftag).toLocaleDateString('de-DE', dateOptions) : 'Keine Angabe'}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography variant="datalabel">
                            Rasse
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext">
                            {certificateDetails?.rasse ? certificateDetails?.rasse : 'Keine Angabe'}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography variant="datalabel">
                            Schulterhöhe/Agility Größe
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext">
                            {certificateDetails?.size_number ? certificateDetails?.size_number.replace('.', ',') : '-'}  / {sizeMap[certificateDetails?.size] ? sizeMap[certificateDetails?.size] : sizeMap[0]}
                        </Typography>
                    </Box>
                    
                    <Box>
                        <Typography variant="datalabel">
                            Abstammungsnachweis
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext">
                            {certificateDetails?.hundrasse === 1 ? 'Ja' : 'Nein'}
                        </Typography>
                    </Box>

                    {(() => {if(certificateDetails?.hundrasse === 1) {return (<>
                        
                    <Box>
                        <Typography variant="datalabel">
                            Zuchtbuchnunmmer
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext">
                            {certificateDetails?.zbnr ? certificateDetails?.zbnr : 'Keine Angabe'}
                        </Typography>
                    </Box>
                        
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '8px 4px 8px 4px',
                            background: '#FFFFFF',
                            flexDirection:'column'
                        }}
                    >
                        <Box mr={1}>
                            <img src={Icon} alt="icon" />
                        </Box>

                        <Box>
                            <Typography variant="datalabel">
                                Nachweislich reinrassig
                            </Typography>

                        </Box>
                    </Box>
                    </>)}})()}
                    
                </Box>
            </Box>

            {(() => {
                if (certificateDetails.elu.length > 0) {
                    return (
                        <Box mt={3}>
                            <Grid container spacing={2} style={{display: 'flex', flexDirection: 'column'}}>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="subtitle1" mb={1}>Teams ({certificateDetails.elu ? certificateDetails.elu.length : 0})</Typography>
                                </Grid>
                                {certificateDetails.elu.map((team) =>
                                    <Grid item xs={12} sm={6} key={team.id}>
                                        <TeamCardComponent item={team} isCertificate={true} certificateDetails={certificateDetails}/>
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                    )
                }
            })()}   
            
        </Box>
    )
}

export default CertificateDetails;