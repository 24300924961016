import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Form from '../common/form/Form.jsx';
import Version from '../version/Version.jsx';

import { login } from './auth-api.js';
import useAuth from '../../hooks/useAuth.js';
import { jwtDecode } from 'jwt-decode';

const Login = function () {

	const [err, setErr] = useState('');

	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword(!showPassword);
	const handleMouseDownPassword = () => setShowPassword(!showPassword);

	const { setAuth } = useAuth();
	const navigate = useNavigate();
	const location = useLocation();
	const from = location?.state?.from.pathname ?? '/uebersicht';



	const onSubmit = async (user) => {
		try {
			const { data } = await login(user);

			const decodedToken = jwtDecode(data?.token);

			localStorage.setItem('user', JSON.stringify(decodedToken));
			localStorage.setItem('token', data.token);
			setAuth({ user: decodedToken, token: data.token });
			navigate(from, { replace: true });

		} catch (error) {
			setErr(error?.response?.data?.message);
		}
	};

	const formData = [
		{
			label: 'E-Mail-Adresse / Benutzername',
			id: 'username',
			required: 'Erforderliches Feld',
			fullWidth: true,
		},
		{
			label: 'Passwort',
			id: 'password',
			type: 'password',
			required: 'Erforderliches Feld',
			showPassword: showPassword,
			handleClickShowPassword: handleClickShowPassword,
			handleMouseDownPassword: handleMouseDownPassword,
		},
	];

	return (
		<Container maxWidth="sm" sx={{ paddingY: '144px', justifyContent: 'center', textAlign: 'center' }}>
			<Typography my={4} ml={1} variant="loginheadertext">Login
			</Typography>
			<Form formData={formData} onSubmit={onSubmit} responseError={err}>
				<Box mt={4}>
					<Button
						type="submit"
						variant="contained"
						fullWidth
						sx={{height: '36px'}}
					>
						<Typography variant="cardtext">
							Einloggen
						</Typography>
					</Button>
				</Box>
			</Form>
			
			<Box mt={2}
                 style={{
                     display: 'flex',
                     alignItems: 'center'
                 }}
                 onClick={() => navigate('/forgotpw')}
            >
                <Typography variant="backlink" >
                    Passwort vergessen?
                </Typography>
            </Box>
			<Version />
		</Container>
	);

};

export default Login;
