import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import AuthProvider from './contexts/AuthContext';
import { ThemeProvider } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';

import theme from './styles/theme';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ToastContainer position="top-center"/>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
    onUpdate: registration => {
      toast.info(`Eine neue Version ist verfügbar. Klicken Sie hier um das Update auszuführen und die App neu zu laden. Sollte die App nicht automatisch neu geladen werden, laden Sie bitte die App durch nach unten wischen manuell neu, oder schließen Sie alle Tabs und starten die App neu.`, {
        toastId: "appUpdateAvailable",
        onClick: () => doUpdate(registration),
        autoClose: false,
        closeButton: false,
      });
    }
  });

function doUpdate(registration) {
    if (registration && registration.waiting) {
        caches.keys().then((keyList) => Promise.all(
            keyList.map((key) => {
                return caches.delete(key);
            }))
            .then(() => {
                registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                window.location.reload();
            })
            .catch((error) => {
                console.log(error);
                registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                window.location.reload();
            })
        );
    }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
