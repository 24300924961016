import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Form from '../common/form/Form.jsx';
import Version from '../version/Version.jsx';

import { forgotpw } from './auth-api.js';

import Vector from '../../assets/icons/Vector.png'

const Forgotpw = function () {

	const [err, setErr] = useState('');
	const [suc, setSuc] = useState('');
	const navigate = useNavigate();

	const onSubmit = async (username) => {
		try {
			const { data } = await forgotpw(username);
			setErr('');
			setSuc(data?.message);
		} catch (error) {
			setSuc('');
			setErr(error?.response?.data?.message);
		}
	};

	const formData = [
		{
			label: 'Benutzername',
			id: 'username',
			required: 'Erforderliches Feld',
			fullWidth: true,
		},
	];

	return (
		<Container maxWidth="sm" sx={{ paddingY: '144px', justifyContent: 'center', textAlign: 'center' }}>
			<Typography my={4} ml={1} variant="loginheadertext">
				Passwort vergessen
			</Typography>
			<Box mb={4}>
				<Typography variant="datalabel">
					Geben Sie ihren Benutzernamen ein und wir senden Ihnen eine E-Mail mit einem Link, um Ihr Passwort zurückzusetzen.
				</Typography>
			</Box>
			<Form formData={formData} onSubmit={onSubmit} responseError={err} responseSuccess={suc}>
				<Box mt={4}>
					<Button
						type="submit"
						variant="contained"
						fullWidth
						sx={{height: '36px'}}
					>
						<Typography variant="cardtext">
							Abschicken
						</Typography>
					</Button>
				</Box>
			</Form>
			
			<Box mt={2}
                 style={{
                     display: 'flex',
                     alignItems: 'center'
                 }}
                 onClick={() => navigate('/login')}
            >
				<Box mr={1}>
                    <img src={Vector} alt="iconLeft"/>
                </Box>
				
                <Typography variant="backlink">
                    Zurück zum Login
                </Typography>
            </Box>
			<Version />
		</Container>
	);

};

export default Forgotpw;
