import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';

import Login from './components/auth/Login';
import Forgotpw from './components/auth/Forgotpw';
import Resetpw from './components/auth/Resetpw';
import CheckIfLoged from './components/auth/CheckIfLoged';
import Redirect from './routes/Redirect';
import HomePage from './components/home/HomePage';
import RequireAuth from './components/auth/RequireAuth';
import Layout from './components/layout/Layout.jsx';
import AssocitaionDetails from './association/AssociationDetails.jsx';
import CertificateDetails from './certificate/CertificateDetails.jsx';
import UserDetails from './userdata/UserDetails.jsx';
import Team from './elu/Team.jsx';
import AgilityList from './elu/AgilityList.jsx';
import AgilityDetails from './elu/AgilityDetails.jsx';
import NoMatch from './routes/No-Match.jsx';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Redirect />} />
        <Route element={<CheckIfLoged />}>
          <Route path="/login" element={<Login />} />
          <Route path="/forgotpw" element={<Forgotpw />} />
          <Route path="/resetpw" element={<Resetpw />} />
        </Route>
        <Route element={< Layout />}>
          <Route element={<RequireAuth />}>
            <Route path="/uebersicht" element={<HomePage />} />
            <Route path="/mitgliedschaftsdetails" element={<AssocitaionDetails />} />
            <Route path="/leistungsurkunde" element={<CertificateDetails />} />
            <Route path="/benutzer" element={<UserDetails />} />
            <Route path="/elu/team" element={<Team />} />
            <Route path="/elu/agility/list" element={<AgilityList />} />
            <Route path="/elu/agility/detail" element={<AgilityDetails />} />
          </Route>
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
