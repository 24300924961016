import React from 'react';
import { Box, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate, useLocation } from 'react-router-dom';
import CardContainer from "./CardContainer";
import Circle  from "./Circle";

const EluCardComponent = ({ item, route = 'agility' }) => {
    const navigate = useNavigate();
    const location = useLocation();
    
    const agilityData = location.state;

    const handleClick = () => {
        navigate('/elu/' + route + '/detail', { state: {data: agilityData?.data, item: item }});
    }

    return (
        <Box style={{ display: 'flex' }} onClick={() => handleClick()}>
            <CardContainer>
                <Circle />
                <Typography variant="cardtext">
                    {item?.trn_tid ? `NR: ${item?.trn_tid} / ${item?.trn_nam}` : item?.trn_nam}
                </Typography>
                <ChevronRightIcon sx={{ marginLeft: 'auto' }} />
            </CardContainer>
        </Box>
    );
};

export default EluCardComponent;
