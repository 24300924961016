import React from 'react';
import { Box, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from 'react-router-dom';
import CardContainer from "./CardContainer";
import Circle from './Circle';

const CardComponent = ({ item, isCertificate = false, certificates, fromAssociation = false }) => {

    const navigate = useNavigate();

    const handleClick = () => {
        if (isCertificate) {
            navigate('/leistungsurkunde', { state: {item, fromAssociation} });
        } else {
            navigate('/mitgliedschaftsdetails', { state: {item, certificates} });
        }
    }

    return (
        <Box style={{ display: 'flex' }} onClick={() => handleClick(isCertificate)}>
            <CardContainer>
                    <Circle />
                <Typography variant="cardtext">
                    {!isCertificate ? (
                        item?.verein_name + ` (${item?.vereins_nr})`
                    ) : (
                        (item?.hundname ? item?.hundname : item?.hundrufname ? item?.hundrufname : `Kein Zwinger- oder Rufname`) + ` (NR: ${item?.nummer})`
                    )}
                </Typography>
                <ChevronRightIcon sx={{ marginLeft: 'auto' }} />
            </CardContainer>
        </Box>
    );
};

export default CardComponent;
