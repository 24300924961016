import {styled} from '@mui/system';

const Circle = styled('div')(({type = 'success'}) => ({
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    backgroundColor: getColor(type),
    marginRight: '10px',
    alignSelf: 'center',
    flexShrink: 0,
}));

const getColor = (type) => {
    switch (type) {
        case 'success':
            return '#9ECD2B';
        case 'warning':
            return '#FFC107';
        case 'error':
            return '#C10435';
        default:
            return '#9ECD2B';
    }
}

export default Circle;
