import React from 'react';
import { Box, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from 'react-router-dom';
import CardContainer from "./CardContainer";
import Circle  from "./Circle";

const TeamCardComponent = ({ item, isCertificate = false, certificateDetails = null }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/elu/team', { state: {item, isCertificate, certificateDetails} });
    }

    return (
        <Box style={{ display: 'flex' }} onClick={() => handleClick()}>
            <CardContainer>
                <Circle />
                <Typography variant="cardtext">
                    {item?.team_nr ? `Team-NR: ${item?.team_nr}` : `Keine Team-NR`}
                </Typography>
                <ChevronRightIcon sx={{ marginLeft: 'auto' }} />
            </CardContainer>
        </Box>
    );
};

export default TeamCardComponent;
