import React from 'react';
import {Typography, Grid, Box} from '@mui/material';

const Version = () => {
    const version = "1.0.5";

    return (
        <Box m={3} align="center" pb={2}>
            <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" mb={1} sx={{fontSize: '0.75rem'}}>Version: {version}</Typography>
            </Grid>
        </Box>
    )
}

export default Version;
