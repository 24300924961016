import React from 'react';
import {Box, Typography} from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';
import Vector from '../assets/icons/Vector.png'

const AgilityDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const agilityData = location.state;
    
    return (
        <Box width='100%'>
            <Box mb={2}
                style={{
                    display: 'flex', alignItems: 'center', color: '#5A6872'
                }}
                onClick={() => navigate(-1)}
            >
                <Box mr={1}>
                    <img src={Vector} alt="iconLeft"/>
                </Box>

                <Typography variant="backlink">
                    Zurück zur Ergebnis-Übersicht des Teams
                </Typography>
            </Box>

            <Typography mb={2} variant="headertext">
                Turnier
            </Typography>

            <Box mb={2} style={{
                background: '#F0F4F8'
            }}>
                <Box p={2}>
                    <Box>
                        <Typography variant="datalabel">
                            Sportart
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext" sx={{ fontWeight: 700 }}>
                            Agility
                        </Typography>
                    </Box>
                    
                    <Box>
                        <Typography variant="datalabel">
                            Turnier-ID
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext">
                            {agilityData?.item.trn_tid ? agilityData?.item.trn_tid :  `Keine Turnier-ID vorhanden`}
                        </Typography>
                    </Box>
                    
                    <Box>
                        <Typography variant="datalabel">
                            Prüfung
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext">
                            {agilityData?.item.trn_nam}
                        </Typography>
                    </Box>
                    
                    <Box>
                        <Typography variant="datalabel">
                            Datum
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext">
                            {agilityData?.item.trn_dat}
                        </Typography>
                    </Box>
                    
                    <Box>
                        <Typography variant="datalabel">
                            Vereinsnummer
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext">
                            {agilityData?.item.trn_vnr}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="datalabel">
                            Verband
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext">
                            {agilityData?.item.trn_veb}
                        </Typography>
                    </Box>
                    
                    <Box>
                        <Typography variant="datalabel">
                            Prüfungsleiter
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext">
                            {agilityData?.item.trn_prl}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            
            {(() => {if(agilityData?.item.teamdata) {return (<>
            <Typography mb={2} variant="headertext">
                Ergebnisse ({agilityData?.item.teamdata.length})
            </Typography>
                
            <Box mb={2} style={{
                background: '#F0F4F8'
            }}>
                {
                    agilityData?.item.teamdata?.map((data) => 
                    <Box p={2} key={data.id}>
                        <Box>
                            <Typography variant="datalabel">
                                Hundename
                            </Typography>
                        </Box>
            
                        <Box mb={2}>
                            <Typography variant="datatext">
                                {data?.tea_hnm}
                            </Typography>
                        </Box>
                        
                        <Box>
                            <Typography variant="datalabel">
                                Mitgliedsnummer des Hundeführers
                            </Typography>
                        </Box>
            
                        <Box mb={2}>
                            <Typography variant="datatext">
                                {data?.tea_mgn}
                            </Typography>
                        </Box>
                        
                        <Box>
                            <Typography variant="datalabel">
                                Größenklasse Agility
                            </Typography>
                        </Box>
            
                        <Box mb={2}>
                            <Typography variant="datatext">
                                {data?.tea_siz}
                            </Typography>
                        </Box>
                        
                        <Box>
                            <Typography variant="datalabel">
                                Para
                            </Typography>
                        </Box>
            
                        <Box mb={2}>
                            <Typography variant="datatext">
                                {data?.tea_par === 1 ? `Ja` : `Nein`}
                            </Typography>
                        </Box>
                        
                        <Box>
                            <Typography variant="datalabel">
                                Leistungsklasse
                            </Typography>
                        </Box>
            
                        <Box mb={2}>
                            <Typography variant="datatext">
                                {data?.tea_lkl}
                            </Typography>
                        </Box>
                        
                        <Box>
                            <Typography variant="datalabel">
                                Leistungsrichter
                            </Typography>
                        </Box>
            
                        <Box mb={2}>
                            <Typography variant="datatext">
                                {data?.prc_ric}
                            </Typography>
                        </Box>
                        
                        <Box>
                            <Typography variant="datalabel">
                                Prüfung
                            </Typography>
                        </Box>
            
                        <Box mb={2}>
                            <Typography variant="datatext">
                                {data?.prc_nam}
                            </Typography>
                        </Box>
                        
                        <Box>
                            <Typography variant="datalabel">
                                Wertung
                            </Typography>
                        </Box>
            
                        <Box mb={2}>
                            <Typography variant="datatext">
                                {data?.prc_wrt}
                            </Typography>
                        </Box>
                        
                        <Box>
                            <Typography variant="datalabel">
                                Rang
                            </Typography>
                        </Box>
            
                        <Box mb={2}>
                            <Typography variant="datatext">
                                {data?.prc_rng}
                            </Typography>
                        </Box>
                        
                        <Box>
                            <Typography variant="datalabel">
                                Zeitfehlerpunkte
                            </Typography>
                        </Box>
            
                        <Box mb={2}>
                            <Typography variant="datatext">
                                {data?.prc_zfp}
                            </Typography>
                        </Box>
                        
                        <Box>
                            <Typography variant="datalabel">
                                Speed
                            </Typography>
                        </Box>
            
                        <Box mb={2}>
                            <Typography variant="datatext">
                                {data?.prc_spe}
                            </Typography>
                        </Box>
                        
                        <Box>
                            <Typography variant="datalabel">
                                Gesamtpunkte
                            </Typography>
                        </Box>
            
                        <Box mb={2}>
                            <Typography variant="datatext">
                                {data?.prc_gpk}
                            </Typography>
                        </Box>
                    </Box>
                    )
                }
            </Box>
            </>)}})()}
            
        </Box>
    )
}

export default AgilityDetails;