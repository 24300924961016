import React from 'react';
import EluCardComponent from '../components/itemCards/eluCard';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography, Grid, Box } from '@mui/material';
import Vector from '../assets/icons/Vector.png';

const AgilityList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    
    const agilityData = location.state;

    return (
        <Box>
            <Box mb={2}
                 style={{
                     display: 'flex',
                     alignItems: 'center',
                     color: '#5A6872'
                 }}
                 onClick={() => navigate(-1)}
            >
                <Box mr={1}>
                    <img src={Vector} alt="iconLeft"/>
                </Box>

                <Typography variant="backlink">
                    Zurück zum Team
                </Typography>
            </Box>
            
            <Grid container spacing={2} style={{display: 'flex', flexDirection: 'column'}}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1" mb={1} sx={{
                        fontWeight: 'bold',
                        fontSize: '1.1rem'
                    }}>Ergebnisse</Typography>
                </Grid>
                {
                    agilityData?.data?.agility.map((elu) =>
                        <Grid item xs={12} sm={6} key={elu.id}>
                            <EluCardComponent item={elu}/>
                        </Grid>
                    )
                }
            </Grid>
        </Box>
    )
};

export default AgilityList;