import React from 'react';
import { Typography, Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import Vector from '../assets/icons/Vector.png'

const UserDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const userDetails = location.state;

    const dateOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };

    const genderMap = ['', 'Frau ', 'Herr ', ''];

    return (
        <Box width='100%'>
            <Box mb={2}
                 style={{
                     display: 'flex',
                     alignItems: 'center',
                     color: '#5A6872'
                 }}
                 onClick={() => navigate(-1)}
            >
                <Box mr={1}>
                    <img src={Vector} alt="iconLeft"/>
                </Box>

                <Typography variant="backlink">
                    Zurück zur Übersicht
                </Typography>
            </Box>

            <Typography mb={2} variant="headertext">
                Person
            </Typography>

            <Box mb={2} style={{
                background: '#F0F4F8'
            }}>
                <Box p={2}>
                    <Box>
                        <Typography variant="datalabel">
                            Name
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext" sx={{ fontWeight: 700 }}>
                            {genderMap[userDetails.gender]} {userDetails.title ? userDetails?.title + ' ' : ''}{`${userDetails?.firstname} ${userDetails?.lastname}`}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography variant="datalabel">
                            Personen-Nummer
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext">
                            {userDetails?.pnumber}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography variant="datalabel">
                            Geburtsdatum
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext">
                            {new Date(userDetails?.birthdate).toLocaleDateString('de-DE', dateOptions)}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography variant="datalabel">
                            Straße und Hausnummer
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext">
                            {`${userDetails?.street} ${userDetails?.snumber != null && !userDetails?.snumber.includes(userDetails?.snumber) ? userDetails?.snumber : ''}`}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography variant="datalabel">
                            PLZ & Wohnort
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext">
                            {`${userDetails?.zip} ${userDetails?.city}`}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography variant="datalabel">
                            Land
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext">
                            {userDetails?.state}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography variant="datalabel">
                            E-Mail
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext">
                            {userDetails?.email}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography variant="datalabel">
                            Telefon
                        </Typography>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="datatext">
                            {userDetails?.phone}
                        </Typography>
                    </Box>

                    {(() => {if(userDetails?.funktionaer) {return (<>
                        <Box>
                            <Typography variant="datalabel">
                                Funktion im Verband/Landesverband/Kreisgruppe
                            </Typography>
                        </Box>

                        <Box mb={2}>
                            <Typography variant="datatext">
                                <span style={{whiteSpace: "pre-line"}}>{userDetails?.funktionen}</span>
                            </Typography>
                        </Box>
                    </>)}})()}

                    {(() => {if(userDetails?.leistungsrichter) {return (<>
                        <Box>
                            <Typography variant="datalabel">
                                Leistungsrichter für folgende Sportarten
                            </Typography>
                        </Box>

                        <Box mb={2}>
                            <Typography variant="datatext">
                                <span style={{whiteSpace: "pre-line"}}>{userDetails?.sportarten}</span>
                            </Typography>
                        </Box>
                    </>)}})()}
                </Box>
            </Box>
        </Box>
    )
}

export default UserDetails;

